$(document).ready(function(){
    $('.bxslider').bxSlider({
        auto: true,
        controls: false,
        mode: 'fade',
        speed: 1000,
        onSliderLoad: function(){
            $(".slider").css("visibility", "visible").hide().fadeIn();
        }
    });
    $('.bxslider.banner').bxSlider({
       pager: false,
        onSliderLoad: function(){
            $(".slider").css("visibility", "visible").hide().fadeIn();
        }
    });

    $('.product-details__gallery').bxSlider({
        pager: true,
        controls: false,
        auto: true,
        mode: 'fade',
        speed: 1000,
        onSliderLoad: function(){
            $(".slider").css("visibility", "visible").hide().fadeIn();
        }
    });
});